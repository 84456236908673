import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import NeverStopImproving from '../images/c-class-cabriolet/highlights/never-stop-improving.jpeg'
import OpenRideComfort from '../images/c-class-cabriolet/highlights/open-ride-comfort.jpeg'
import SportyDesign from '../images/c-class-cabriolet/highlights/sporty-design.jpeg'
import DigitalCockpit from '../images/c-class-cabriolet/highlights/digital-cockpit.jpeg'
import IntelligentDrive from '../images/c-class-cabriolet/highlights/intelligent-drive.jpeg'
import AmbianceLighting from '../images/c-class-cabriolet/highlights/ambiance-lighting.jpeg'

import HeaderImage from '../images/c-class-cabriolet/header-c-class-cabriolet.jpeg'

import InterieurImage from '../images/c-class-cabriolet/gallery/interieur-teaser-c-class-cabriolet.jpeg'
import ExterieurImage from '../images/c-class-cabriolet/gallery/exterieur-teaser-c-class-cabriolet.jpeg'

import InterieurImageGallery1 from '../images/c-class-cabriolet/gallery/gallery1-interieur-c-class-cabriolet.jpeg'
import InterieurImageGallery2 from '../images/c-class-cabriolet/gallery/gallery2-interieur-c-class-cabriolet.jpeg'
import InterieurImageGallery3 from '../images/c-class-cabriolet/gallery/gallery3-interieur-c-class-cabriolet.jpeg'
import InterieurImageGallery4 from '../images/c-class-cabriolet/gallery/gallery4-interieur-c-class-cabriolet.jpeg'
import InterieurImageGallery5 from '../images/c-class-cabriolet/gallery/gallery5-interieur-c-class-cabriolet.jpeg'

import ExterieurImageGallery1 from '../images/c-class-cabriolet/gallery/gallery1-exterieur-c-class-cabriolet.jpeg'
import ExterieurImageGallery2 from '../images/c-class-cabriolet/gallery/gallery2-exterieur-c-class-cabriolet.jpeg'
import ExterieurImageGallery3 from '../images/c-class-cabriolet/gallery/gallery3-exterieur-c-class-cabriolet.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
  InterieurImageGallery4,
  InterieurImageGallery5,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
]

const CClassCabriolet = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Never stop improving. Das neue C-Klasse Cabriolet.</H1>
      <p>
        Nur wenige Wochen nach der Weltpremiere von Limousine und T-Modell
        präsentieren sich mit Coupé und Cabriolet auch die Zweitürer der
        C-Klasse in neuer Bestform. Mit geschärftem Design, digitalem Cockpit
        und DYNAMIC BODY CONTROL Fahrwerk wurden Sportlichkeit und Fahrspaß
        gesteigert. Zum Geheimnis des C-Klasse Erfolgs gehört die
        breitgefächerte Modellpalette, die auch zwei sportliche Zweitürer
        umfasst: Das Ende 2015 eingeführte Coupé und das seit Sommer 2016
        erhältliche Cabriolet sprechen Herz und Verstand gleichermaßen an. Das
        Design der Fahrzeuge ist einer der Hauptkaufgründe in Europa. Beide
        Modelle werden im Mercedes-Benz Werk Bremen produziert.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>
        Sich niemals auszuruhen, sondern Innovationskraft immer wieder neu zu
        beweisen.
      </H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={NeverStopImproving}
          title="Never stop improving"
          headline="Never stop improving"
          description=""
        />
        <ImageBox
          imagesrc={OpenRideComfort}
          title="Einzigartiger Offenfahrkomfort"
          headline="Einzigartiger Offenfahrkomfort."
          description="Treiben Sie mehr Sport, am besten unter freiem Himmel. Das neue 
          C-Klasse Cabriolet verlängert die Open-Air-Saison auf das ganze Jahr. 
          Mit einem Offenfahrkomfort vom Feinsten: Die Kombination aus 
          automatischem Windschott AIRCAP und Kopfraumheizung AIRSCARF macht Sie unabhängig von Wind und Wetter und schafft optimale Traningsbedingungen."
        />
        <ImageBox
          imagesrc={SportyDesign}
          title="Sportliches Design"
          headline="Sportliches Design."
          description="Das neue C-Klasse Cabriolet zeigt noch mehr Vorwärtsdrang: Insbesondere die AMG Line mit neuer Front- und Heckschürze strahlt Kraft und Sportlichkeit aus. Zudem faszinieren innovative MULTIBEAM LED Scheinwerfer sowie neue Lackfaben: zum Beispiel das sportlich-stilvolle Mojavesilber metallic."
        />
        <ImageBox
          imagesrc={DigitalCockpit}
          title="Digitales Cockpit"
          headline="Hier wird die Zukunft angezeigt."
          description="Ganz neu sind viele Bedien- und Anzeigeelemente: Das neue Lenkrad mit Touch Control Buttons sowie die je nach Ausstattung volldigitalen Instrumente und vergrößerten Displays, inklusive neuer Infotainment-Generation, ermöglichen eine höchst individuelle und moderne Bedienung. Alle Instrumente können Sie jetzt zudem ausstattungsabhängig in den Anzeigestilen „Klassisch“, „Sportlich“ und „Progressiv“ beleuchten und animieren lassen."
        />
        <ImageBox
          imagesrc={IntelligentDrive}
          title="Mercedes-Benz Intelligent Drive"
          headline="Neue Generation Mercedes-Benz Intelligent Drive."
          description="Schnell, präzise, wachsam: Hochmoderne Mercedes-Benz Intelligent Drive Assistenzsysteme entlasten wirkungsvoller denn je. Ob Wiederanfahren im Stau, situationsgerechte Bremskraftunterstützung bei erkannter Kollisionsgefahr oder Distanzeinhaltung auf der Autobahn bei bis zu 210 km/h: Dabei kann das neue C-Klasse Cabriolet den Fahrer schon heute unterstützen. Einen zuverlässigeren und aufmerksameren Partner können Sie sich unterwegs kaum wünschen.  "
        />
        <ImageBox
          imagesrc={AmbianceLighting}
          title="Ambientebeleuchtung"
          headline="64 Farben. Ein einzigartiges Ambiente."
          description='Für jede Stimmung, für jede Tages- und Nachtzeit. Für jeden Anlass und jedes Mal anders: Die Ambientebeleuchtung verwandelt den Innenraum des neuen C-Klasse Cabriolets in eine faszinierende Lounge. 64 Farben können dazu einzeln angewählt werden. Die Ambientebeleuchtung ist auf Wunsch einzeln erhältlich oder als Bestandteil der optionalen ENERGIZING Komfortsteuerung. Ambientelicht mit 64 Farben, Sound und Beduftung werden miteinander vernetzt und können durch Programme wie "Vitalität" oder "Frische" die Leistungsfähigkeit und das Wohlbefinden steigern.'
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default CClassCabriolet
